import { Element } from './textTyping.styled'

function TextTyping({ words, onPictureChange, ...rest }) {
	//
	const [partState, setPartState] = React.useState('')

	React.useEffect(() => {
		wordTyping()
	}, [])

	const wordTyping = () => {
		let i = 0,
			offset = 0,
			len = words.length,
			forwards = true,
			skip_count = 0,
			skip_delay = 15,
			speed = 100

		setInterval(() => {
			if (forwards) {
				if (offset >= words[i].length) {
					++skip_count

					if (skip_count === skip_delay) {
						forwards = false
						skip_count = 0
						onPictureChange(i + 1)
					}
				}
			} else {
				if (offset === 0) {
					forwards = true
					i++
					offset = 0
					if (i >= len) i = 0
				}
			}

			setPartState(words[i].substr(0, offset))

			if (skip_count === 0) {
				if (forwards) offset++
				else offset--
			}
		}, speed)
	}

	return <Element {...rest}>{partState}</Element>
}

TextTyping.propTypes = {
	words: PropTypes.arrayOf(PropTypes.string).isRequired,
	onPictureChange: PropTypes.func,
}

TextTyping.defaultProps = {
	onPictureChange: () => {},
}

export default TextTyping
