export const Element = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 60px 30px;
	box-sizing: border-box;

	${MOBILE} {
		padding: 30px;
	}
`

export const Title = styled.h2`
	font-size: 40px;
	font-weight: 400;
	text-align: center;
	width: 100%;
	max-width: 550px;
	text-align: center;
	margin: 0 auto;

	${MOBILE} {
		font-size: 24px;
	}
`

export const Desc = styled.p`
	font-size: 18px;
	max-width: 480px;
	text-align: center;
	margin: 15px auto 0;
	color: ${({ theme }) => theme.color.gray.one};
`

export const Grid = styled.ul`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 30px;
	margin-top: 60px;

	${MOBILE} {
		margin-top: 30px;
		grid-template-columns: repeat(1, 1fr);
	}
`

export const Item = styled.li`
	position: relative;
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	padding: 30px;
	border-radius: 30px;

	:nth-child(1) {
		background: ${({ theme }) => rgba(theme.color.yellow, 0.1)};
	}

	:nth-child(2) {
		background: ${({ theme }) => rgba(theme.color.green, 0.1)};
	}

	:nth-child(3) {
		background: ${({ theme }) => rgba(theme.color.red, 0.1)};
	}
`

export const ItemTitle = styled.h3`
	font-size: 24px;
	font-weight: 400;
	line-height: 1.4;
	margin-bottom: 15px;
`

export const ItemDesc = styled.p`
	color: ${({ theme }) => theme.color.gray.one};
`

export const ItemIcon = styled.img`
	position: absolute;
	top: 50%;
	right: -15px;
	width: 100%;
	height: calc(100% + 30px);
	object-fit: contain;
	object-position: right;
	transform: translateY(-50%);
	pointer-events: none;
	opacity: 0.7;
`

export const Link = styled.a`
	align-self: center;
	margin-top: 60px;
	color: white;
	border-radius: 100px;
	background: ${({ theme }) => theme.color.green};
	background: ${({ theme }) =>
		`linear-gradient(45deg, ${theme.color.green} 0%, ${rgba(theme.color.green, 0.7)} 100%)`};
	text-shadow: 0 1px 3px ${({ theme }) => rgba(theme.color.black.one, 0.15)};
	padding: 10px 20px;
	font-size: 18px;
	transition: transform 0.3s ease, box-shadow 0.3s ease, text-shadow 0.3s ease;

	:hover {
		transform: translateY(-3px);
		text-shadow: 0 4px 6px ${({ theme }) => rgba(theme.color.black.one, 0.3)};
		box-shadow: 0 3px 9px ${({ theme }) => rgba(theme.color.green, 0.5)};
	}

	${MOBILE} {
		text-align: center;
		max-width: 200px;
		margin-top: 30px;
	}
`
