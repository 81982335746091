import UIItem from './Item'

export const Element = styled.div`
	background: ${({ theme }) => theme.color.black.two};
`

export const WaveImg = styled.img`
	display: table;
	width: calc(100% + 40px);
	transform: translateY(1px) scale(-1, -1);
	pointer-events: none;
	margin: 0 -20px;
`

export const Container = styled.div`
	padding: 30px 0 50px;
	background: #ffcf72;
`

export const Main = styled.div`
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 30px;
	box-sizing: border-box;
`

export const Title = styled.h2`
	font-size: 40px;
	text-align: center;

	b {
		font-weight: 600;
	}
`

export const Grid = styled.ul`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 5px;
	width: 100%;
	margin: 30px auto 0;

	${MOBILE} {
		grid-template-columns: 1fr;
	}
`

export const ItemTitle = styled.div`
	display: flex;
	flex-flow: column;
	padding: 15px;
	margin: -15px;
	border-radius: 15px;
	background-color: rgba(0, 0, 0, 0.05);

	span {
		&:first-child {
			font-size: 26px;
		}
	}
`

export const Item = styled(UIItem)`
	&:nth-child(1) {
		${ItemTitle} {
			background-color: rgba(255, 255, 255, 0.05);
		}
	}
`

export const Link = styled.a`
	display: table;
	color: white;
	border-radius: 100px;
	font-size: 20px;
	margin: 30px auto 0;
	background: ${({ theme }) => theme.color.black.four};
	background: ${({ theme }) =>
		`linear-gradient(45deg, ${theme.color.black.four} 0%, ${rgba(theme.color.black.four, 0.7)} 100%)`};
	text-shadow: 0 1px 3px ${({ theme }) => rgba(theme.color.black.one, 0.15)};
	padding: 15px 30px;
	transition: transform 0.3s ease, box-shadow 0.3s ease, text-shadow 0.3s ease;

	:hover {
		transform: translateY(-3px);
		text-shadow: 0 4px 6px ${({ theme }) => rgba(theme.color.black.one, 0.3)};
		box-shadow: 0 3px 9px ${({ theme }) => rgba(theme.color.black.four, 0.5)};
	}

	${MOBILE} {
		width: 100%;
		text-align: center;
		box-sizing: border-box;
	}
`
