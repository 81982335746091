import UITextTyping from './TextTyping'

export const Element = styled.div`
	margin-top: -130px;
	background: ${({ theme }) => rgba(theme.color.gray.five, 0.5)};

	${MOBILE} {
		margin-top: -89px;
	}
`

export const Container = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 164px 30px 60px;
	box-sizing: border-box;

	${MOBILE} {
		flex-flow: column-reverse;
		padding: 89px 30px 60px;
	}
`

export const Side = styled.div`
	:first-child {
		flex: 1;
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		margin-right: 30px;

		${MOBILE} {
			margin-right: 0;
			margin-top: 30px;
			text-align: center;
		}
	}

	:last-child {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 350px;
		height: 350px;
		background: ${({ theme }) => theme.color.gray.five};
		border-radius: 80px;

		${MOBILE} {
			width: 100%;
		}
	}
`

export const Title = styled.h1`
	font-size: 48px;
	font-weight: 400;
	background: ${({ theme }) => `linear-gradient(192.84deg, ${theme.color.black.five}, ${theme.color.black.one})`};
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	${MOBILE} {
		font-size: 34px;
	}
`

export const TextTyping = styled(UITextTyping)``

export const Desc = styled.p`
	font-size: 18px;
	margin-top: 30px;

	${MOBILE} {
		margin-top: 15px;
	}
`

export const Advantage = styled.ul`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	margin-top: 15px;

	li {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		font-size: 14px;
		color: ${({ theme }) => theme.color.gray.two};

		::after {
			content: '';
			width: 6px;
			height: 6px;
			background: ${({ theme }) => rgba(theme.color.gray.two, 0.3)};
			border-radius: 50%;
			margin: 0 8px;

			${MOBILE} {
				display: none;
			}
		}

		:last-child {
			::after {
				display: none;
			}
		}
	}

	${MOBILE} {
		flex-flow: column;
		margin: 15px auto 0;
	}
`

export const ButtonLink = styled.a`
	color: white;
	border-radius: 100px;
	background: ${({ theme }) => theme.color.blue};
	background: ${({ theme }) => `linear-gradient(45deg, ${theme.color.blue} 0%, ${rgba(theme.color.blue, 0.7)} 100%)`};
	text-shadow: 0 1px 3px ${({ theme }) => rgba(theme.color.black.one, 0.15)};
	margin-top: 30px;
	padding: 10px 20px;
	font-size: 18px;
	transition: transform 0.3s ease, box-shadow 0.3s ease, text-shadow 0.3s ease;

	:hover {
		transform: translateY(-3px);
		text-shadow: 0 4px 6px ${({ theme }) => rgba(theme.color.black.one, 0.3)};
		box-shadow: 0 3px 9px ${({ theme }) => rgba(theme.color.blue, 0.5)};
	}

	${MOBILE} {
		width: 100%;
		box-sizing: border-box;
	}
`

export const Picture = styled.img`
	position: absolute;
	top: 50%;
	left: 50%;
	display: table;
	width: ${({ visible }) => (visible ? 'calc(100% - 60px)' : '0')};
	height: ${({ visible }) => (visible ? 'calc(100% - 60px)' : '0')};
	object-fit: contain;
	object-position: center;
	pointer-events: none;
	transform: translate(-50%, -50%);
	opacity: ${({ visible }) => (visible ? '1' : '0')};
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
	transition: opacity 0.3s ease, visibility 0.3s ease, width 0.3s ease, height 0.3s ease;

	:nth-child(2) {
		width: ${({ visible }) => (visible ? 'calc(100% - 150px)' : '0')};
		height: ${({ visible }) => (visible ? 'calc(100% - 150px)' : '0')};
	}

	:nth-child(3) {
		width: ${({ visible }) => (visible ? 'calc(100% - 100px)' : '0')};
		height: ${({ visible }) => (visible ? 'calc(100% - 100px)' : '0')};
	}

	:nth-child(4) {
		width: ${({ visible }) => (visible ? 'calc(100% - 150px)' : '0')};
		height: ${({ visible }) => (visible ? 'calc(100% - 150px)' : '0')};
	}

	:nth-child(5) {
		right: 0;
		left: initial;
		transform: translateY(-50%);
	}
`

export const Dots = styled.ul`
	position: absolute;
	left: 50%;
	bottom: 15px;
	display: grid;
	grid-template-columns: ${({ counter }) => `repeat(${counter}, 1fr)`};
	grid-gap: 10px;
	transform: translateX(-50%);
`

export const Dot = styled.li`
	width: 8px;
	height: 8px;
	background: ${({ theme, active }) => (active ? theme.color.gray.two : rgba(theme.color.gray.two, 0.15))};
	border-radius: 50%;
	transform: ${({ active }) => (active ? 'scale(1)' : 'scale(0.8)')};
	transition: background 0.3s ease, transform 0.3s ease;
`

export const WaveImg = styled.img`
	display: table;
	width: 100%;
	transform: scale(-1, -1);
	pointer-events: none;
`
