import { routing } from '@app'
import { Element, WaveImg, Container, Main, Title, Grid, Item, ItemTitle, Link } from './tariffs.styled'

import waveSvg from './img/wave.svg'

const tariffsList = [558, 1674, 5580, 11159]

function Tariffs({ ...rest }) {
	//

	return (
		<Element {...rest}>
			<WaveImg src={waveSvg} />

			<Container>
				<Main>
					<Title>Тарифы</Title>

					<Grid>
						<Item>
							<ItemTitle>
								<span>1000 ₽ в месяц</span>
								<span>для тех у кого меньше 600 заказов в месяц</span>
							</ItemTitle>
							<Item.Desc>
								<li>Онлайн-редактор меню</li>
								<li>
									Открывается по QR-коду в заведении или по ссылке из ВКонтакте и других соцсетей.
								</li>
								<li>
									Позволяет гостю оформить заказ в ресторане или из дома. Решение для самовывоза и
									собственной доставки.
								</li>
								<li>Мобильная и Десктопная версии меню</li>
								<li>Настройка аналитики</li>
								<li>Неограниченное количество элементов в меню</li>
								<li>Полный доступ к инструменту QR</li>
								<li>Цвет бренда</li>
							</Item.Desc>
						</Item>
						<Item>
							<ItemTitle>
								<span>2000 ₽ в месяц</span>
								<span>для тех у кого меньше 2000 заказов в месяц</span>
							</ItemTitle>
							<Item.Desc>
								<li>Онлайн-редактор меню</li>
								<li>
									Открывается по QR-коду в заведении или по ссылке из ВКонтакте и других соцсетей.
								</li>
								<li>
									Позволяет гостю оформить заказ в ресторане или из дома. Решение для самовывоза и
									собственной доставки.
								</li>
								<li>Мобильная и Десктопная версии меню</li>
								<li>Настройка аналитики</li>
								<li>Неограниченное количество элементов в меню</li>
								<li>Полный доступ к инструменту QR</li>
								<li>Цвет бренда</li>
							</Item.Desc>
						</Item>
						<Item>
							<ItemTitle>
								<span>5600 ₽ в месяц</span>
								<span>подойдет для тех, кому надо внедрять кастомные решения</span>
							</ItemTitle>
							<Item.Desc>
								<li>Онлайн-редактор меню</li>
								<li>
									Открывается по QR-коду в заведении или по ссылке из ВКонтакте и других соцсетей.
								</li>
								<li>
									Позволяет гостю оформить заказ в ресторане или из дома. Решение для самовывоза и
									собственной доставки.
								</li>
								<li>Мобильная и Десктопная версии меню</li>
								<li>Настройка аналитики</li>
								<li>Неограниченное количество элементов в меню</li>
								<li>Полный доступ к инструменту QR</li>
								<li>Цвет бренда</li>
							</Item.Desc>
						</Item>
						<Item>
							<ItemTitle>
								<span>16800 ₽ в месяц</span>
								<span>развертывание и обслуживание на вашем сервере</span>
							</ItemTitle>
							<Item.Desc>
								<li>Онлайн-редактор меню</li>
								<li>
									Открывается по QR-коду в заведении или по ссылке из ВКонтакте и других соцсетей.
								</li>
								<li>
									Позволяет гостю оформить заказ в ресторане или из дома. Решение для самовывоза и
									собственной доставки.
								</li>
								<li>Мобильная и Десктопная версии меню</li>
								<li>Настройка аналитики</li>
								<li>Неограниченное количество элементов в меню</li>
								<li>Полный доступ к инструменту QR</li>
								<li>Цвет бренда</li>
							</Item.Desc>
						</Item>
					</Grid>

					<Link href={routing.signupRoute} target="_blank">
						Попробовать бесплатно
					</Link>
				</Main>
			</Container>
		</Element>
	)
}

export default Tariffs
