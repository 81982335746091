import {
	Element,
	Side,
	Title,
	Desc,
	WorksWith,
	Grid,
	Item,
	ItemIconWrapper,
	ItemIcon,
	ItemMain,
	Picture,
} from './byeMenu.styled'

import appleSvg from './img/apple.svg'
import androidSvg from './img/android.svg'
import globeSvg from './img/globe.svg'
import qrCodeSvg from './img/qr-code.svg'

function ByeMenu({ ...rest }) {
	//

	return (
		<Element {...rest}>
			<Side>
				<Picture src={qrCodeSvg} />
			</Side>
			<Side>
				<Title>
					Прощай физическое меню
					<br />
					<span>Привет электронное</span>
				</Title>

				<Desc>
					Ваше электронное меню можно просматривать на любом устройстве без необходимости загружать
					приложение! Клиенты мгновенно получают доступ к вашему меню, перейдя по ссылке или отсканировав
					QR-код камерой своего гаджета.
				</Desc>

				<WorksWith>Работает с:</WorksWith>

				<Grid>
					<Item>
						<ItemIconWrapper>
							<ItemIcon src={appleSvg} />
						</ItemIconWrapper>
						<ItemMain>
							<span>Safari</span>
							<span>iOS</span>
						</ItemMain>
					</Item>

					<Item>
						<ItemIconWrapper>
							<ItemIcon src={androidSvg} />
						</ItemIconWrapper>
						<ItemMain>
							<span>Chrome</span>
							<span>Android</span>
						</ItemMain>
					</Item>

					<Item>
						<ItemIconWrapper>
							<ItemIcon src={globeSvg} />
						</ItemIconWrapper>
						<ItemMain>
							<span>Любой браузер</span>
							<span>Любая платформа</span>
						</ItemMain>
					</Item>
				</Grid>
			</Side>
		</Element>
	)
}

export default ByeMenu
