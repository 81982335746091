const animate = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`

export const Element = styled.span`
	::after {
		content: '';
		display: inline-block;
		width: 2px;
		height: 50px;
		transform: translateY(10px) translateX(5px);
		background: ${({ theme }) => theme.color.gray.two};
		animation-name: ${animate};
		animation-duration: 0.7s;
		animation-iteration-count: infinite;

		${MOBILE} {
			height: 30px;
			transform: translateY(5px) translateX(5px);
		}
	}
`
