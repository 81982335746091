import { Element, Title, Desc, Grid, Item } from './faq.styled'

function Faq({ ...rest }) {
	//

	return (
		<Element {...rest}>
			<Title>FAQ</Title>

			<Desc>Часто задаваемые вопросы</Desc>

			<Grid>
				<Item>
					<Item.Title>Зачем нужно электронное меню?</Item.Title>
					<Item.Main>
						<ul>
							<li>Гостям не нужно ждать, когда подойдет официант</li>
							<li>
								Гости могут изучить меню с описанием и фотографиями в своем смартфоне, не нужно
								устанавливать приложения
							</li>
							<li>Электронное меню будет всегда под рукой гостя</li>
							<li>Можно быстро включать и выключать блюда в меню</li>
						</ul>
					</Item.Main>
				</Item>

				<Item>
					<Item.Title>С чего начать?</Item.Title>
					<Item.Main>
						<ul>
							<li>Зарегистрироваться в онлайн-редакторе</li>
							<li>
								Внести в редакторе свое меню — это не сложнее чем заполнить профиль в социальной сети.
								Однако мы всегда рады помочь и сэкономить ваше время. Мы можем импортировать ваше меню и
								помочь с визуальным оформлением. Обращайтесь в службу поддержки help@meenu.ru или
								телеграм
							</li>
							<li>Распечатайте таблички с QR-кодом и поставьте на столы</li>
						</ul>
					</Item.Main>
				</Item>

				<Item>
					<Item.Title>Где будет храниться мое меню? Мне нужен сайт?</Item.Title>
					<Item.Main>
						Меню ресторана создается в онлайн-редакторе и хранится на серверах сервиса meenu.ru. Все заботы
						о том, чтобы меню было всегда доступно для посетителей, мы берем на себя.
					</Item.Main>
				</Item>

				<Item>
					<Item.Title>Нет времени заниматься внесением меню, можете мне помочь?</Item.Title>
					<Item.Main>
						Вы можете прислать на почту help@meenu.ru или телеграм материалы вашего меню, и наши специалисты
						импортируют его онлайн. Лучше всего, если меню будет в формате EXCEL или PDF, а фотографии блюд
						подписаны.
					</Item.Main>
				</Item>

				<Item>
					<Item.Title>Смогут ли посетители посмотреть мое меню с любого устройства?</Item.Title>
					<Item.Main>Да, сервис доступен на любом смартфоне/компьютере.</Item.Main>
				</Item>

				<Item>
					<Item.Title>Как гости найдут меню ресторана?</Item.Title>
					<Item.Main>
						<ul>
							<li>
								Офлайн — разместите qr-код в виде тейбл-тент, сервировочной тарелки, наклейки на столах
								или барной стойке, визитки, меню
							</li>
							<li>
								Онлайн — разместите ссылку у себя в соцсетях, на сайте ресторана, зарегистрируйте
								сайт-меню в Google / Yandex, добавьте ссылку в Trip Advisor, на сайты-каталоги,
								известные вашим клиентам
							</li>
						</ul>
					</Item.Main>
				</Item>

				<Item>
					<Item.Title>Сколько это стоит?</Item.Title>
					<Item.Main>
						Первый месяц meenu.ru Вы получаете бесплатно сразу после регистрации. Далее — 3% от успешных
						заказов.
					</Item.Main>
				</Item>
			</Grid>
		</Element>
	)
}

export default Faq
