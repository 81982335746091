import { routing } from '@app'
import { Element, Title, Desc, Grid, Item, ItemTitle, ItemDesc, ItemIcon, Link } from './saferBusiness.styled'

import filePdfSvg from './img/file-pdf.svg'
import virusSvg from './img/virus.svg'
import handHoldingUsdSvg from './img/hand-holding-usd.svg'

function SaferBusiness({ ...rest }) {
	//

	return (
		<Element {...rest}>
			<Title>Быстрые продажи и безопасный бизнес</Title>

			<Desc>Система, которая позволяет клиентам оформлять заказы быстро и сокращать количество ошибок</Desc>

			<Grid>
				<Item>
					<ItemTitle>Обычное меню в PDF работает неэффективно</ItemTitle>
					<ItemDesc>
						Долго грузится, слишком мелкое на экране смартфона, неудобно выбирать блюда: клиенты остаются
						недовольными, продажи и средний чек падают
					</ItemDesc>
					<ItemIcon src={filePdfSvg} />
				</Item>

				<Item>
					<ItemTitle>Безопасность для гостей и персонала</ItemTitle>
					<ItemDesc>
						Уменьшите физический контакт между клиентами во время COVID-19 с помощью электронного меню
					</ItemDesc>
					<ItemIcon src={virusSvg} />
				</Item>

				<Item>
					<ItemTitle>Сторонние сервисы забирают всю прибыль</ItemTitle>
					<ItemDesc>
						Агрегаторы и приложения берут большую комиссию от заказа, в итоге даже при росте выручки ваш
						бизнес развивается не так быстро, как мог бы
					</ItemDesc>
					<ItemIcon src={handHoldingUsdSvg} />
				</Item>
			</Grid>

			<Link href={routing.demoRoute} target="_blank">
				Открыть пример электронного меню
			</Link>
		</Element>
	)
}

export default SaferBusiness
