import UIItem from './Item'

export const Element = styled.div``

export const WaveImg = styled.img`
	display: table;
	width: calc(100% + 40px);
	transform: translateY(1px) scale(-1, -1);
	pointer-events: none;
	margin: 0 -20px;
`

export const Container = styled.div`
	color: white;
	padding: 30px 0 50px;
	background: ${({ theme }) => theme.color.black.two};
`

export const Main = styled.div`
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 30px;
	box-sizing: border-box;
`

export const Title = styled.h2`
	font-size: 40px;
	text-align: center;

	${MOBILE} {
		font-size: 24px;
	}
`

export const Grid = styled.ul`
	display: grid;
	grid-template-columns: repeat(1fr, 1);
	grid-gap: 15px;
	margin-top: 30px;
`

export const Item = styled(UIItem)``

export const Desc = styled.p`
	text-align: center;
	margin-top: 30px;
	font-size: 18px;

	b {
		color: ${({ theme }) => theme.color.blue};
	}
`

export const Link = styled.a`
	display: table;
	margin: 30px auto 0;
	font-size: 18px;
	color: white;
	border-radius: 100px;
	background: ${({ theme }) => theme.color.blue};
	background: ${({ theme }) => `linear-gradient(45deg, ${theme.color.blue} 0%, ${rgba(theme.color.blue, 0.7)} 100%)`};
	text-shadow: 0 1px 3px ${({ theme }) => rgba(theme.color.black.one, 0.15)};
	padding: 10px 20px;
	transition: transform 0.3s ease, box-shadow 0.3s ease, text-shadow 0.3s ease;

	:hover {
		transform: translateY(-3px);
		text-shadow: 0 4px 6px ${({ theme }) => rgba(theme.color.black.one, 0.3)};
		box-shadow: 0 3px 9px ${({ theme }) => rgba(theme.color.blue, 0.5)};
	}

	${MOBILE} {
		text-align: center;
		max-width: 200px;
	}
`
