import { Element, WaveImg, Container, Main, Title, Grid, Item, Desc, Link } from './howWorks.styled'

import waveSvg from './img/wave.svg'
import qrcodeSvg from './img/qrcode.svg'
import cartPlusSvg from './img/cart-plus.svg'
import paperPlaneSvg from './img/paper-plane.svg'
import headsetSvg from './img/headset.svg'
import userCheckSvg from './img/user-check.svg'

function HowWorks({ ...rest }) {
	//

	return (
		<Element {...rest}>
			<WaveImg src={waveSvg} />
			<Container>
				<Main>
					<Title>Как это работает?</Title>

					<Grid>
						<Item>
							<Item.Count>1</Item.Count>
							<Item.Title>Гость открывает меню кликнув по ссылке или отсканировав QR-код</Item.Title>
							<Item.Icon src={qrcodeSvg} />
						</Item>
						<Item>
							<Item.Count>2</Item.Count>
							<Item.Title>Выбирает и заказывает товары по электронному меню</Item.Title>
							<Item.Icon src={cartPlusSvg} />
						</Item>
						<Item>
							<Item.Count>3</Item.Count>
							<Item.Title>
								Оформляет заказ на самовывоз, доставку или внутри ресторана и отправляет заказ одной
								кнопкой
							</Item.Title>
							<Item.Icon src={paperPlaneSvg} />
						</Item>
						<Item>
							<Item.Count>4</Item.Count>
							<Item.Title>Ваш менеджер обрабатывает заказ</Item.Title>
							<Item.Icon src={headsetSvg} />
						</Item>
						<Item>
							<Item.Count>5</Item.Count>
							<Item.Title>Гость быстро получает свой заказ</Item.Title>
							<Item.Icon src={userCheckSvg} />
						</Item>
					</Grid>

					<Desc>
						<b>meenu.ru</b> повышает качество обслуживания, а клиенты остаются довольными!
					</Desc>

					<Link href="https://demo.meenu.ru" target="_blank">
						Открыть пример электронного меню
					</Link>
				</Main>
			</Container>
		</Element>
	)
}

export default HowWorks
