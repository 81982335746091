import UIBanner from './Banner'
import UIByeMenu from './ByeMenu'
import UISaferBusiness from './SaferBusiness'
import UIHowWorks from './HowWorks'
import UITariffs from './Tariffs'
import UIFaq from './Faq'

export const Element = styled.div`
	flex: 1;
	display: flex;
	flex-flow: column;
	width: 100%;
`

export const Banner = styled(UIBanner)``

export const ByeMenu = styled(UIByeMenu)``

export const SaferBusiness = styled(UISaferBusiness)``

export const HowWorks = styled(UIHowWorks)``

export const Tariffs = styled(UITariffs)``

export const Faq = styled(UIFaq)``
