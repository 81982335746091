export const Element = styled.li`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	padding: 15px 30px;
	border-radius: 30px;
	background: ${({ theme }) => rgba(theme.color.gray.two, 0.1)};

	${MOBILE} {
		flex-flow: column;
		padding: 30px;
	}
`

export const Count = styled.div`
	width: 48px;
	height: 48px;
	color: ${({ theme }) => rgba(theme.color.gray.two, 0.7)};
	border: 2px solid ${({ theme }) => rgba(theme.color.gray.two, 0.15)};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	margin-right: 30px;
	margin-left: -15px;

	${MOBILE} {
		margin: 0 0 15px;
	}
`

export const Title = styled.h3`
	flex: 1;
	font-size: 20px;

	${MOBILE} {
		margin-bottom: 15px;
		text-align: center;
	}
`

export const Icon = styled(UIIcon)`
	width: 42px;
	height: 42px;
	fill: white;
`
