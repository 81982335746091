import { Element, Banner, ByeMenu, SaferBusiness, HowWorks, Tariffs, Faq } from './home.styled'

function HomePage({ ...rest }) {
	//

	return (
		<Element {...rest}>
			<Banner />
			<ByeMenu />
			<SaferBusiness />
			<HowWorks />
			<Tariffs />
			<Faq />
		</Element>
	)
}

export default HomePage
