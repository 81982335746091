export const Element = styled.li`
	padding: 30px;
	border-radius: 30px;
	background: white;

	:nth-child(1) {
		color: white;
		background: ${({ theme }) => theme.color.black.four};
	}
`

export const Title = styled.var`
	font-size: 36px;

	${MOBILE} {
		font-size: 28px;
		font-weight: 600;
	}
`

export const Currency = styled.abbr`
	font-size: 24px;

	${MOBILE} {
		font-size: 18px;
	}
`

export const Desc = styled.ul`
	margin-top: 30px;

	li {
		font-size: 14px;

		::after {
			content: '';
			display: block;
			width: 30px;
			height: 2px;
			background: ${({ theme }) => theme.color.black.one};
			margin: 10px 0;
			opacity: 0.15;
		}

		:last-child {
			::after {
				display: none;
			}
		}
	}

	${Element}:nth-child(1) & {
		li {
			::after {
				background: white;
			}
		}
	}
`
