import UIItem from './Item'

export const Element = styled.div`
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 60px 30px;
	box-sizing: border-box;

	${MOBILE} {
		padding: 30px;
	}
`

export const Title = styled.h2`
	font-size: 40px;
	text-align: center;
`

export const Desc = styled.p`
	font-size: 18px;
	max-width: 480px;
	text-align: center;
	margin: 15px auto 0;
	color: ${({ theme }) => theme.color.gray.one};

	${MOBILE} {
		margin: 5px auto 0;
	}
`

export const Grid = styled.ul`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 15px;
	margin-top: 30px;
`

export const Item = styled(UIItem)``
