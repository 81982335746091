import { Element, Count, Title, Icon } from './item.styled'

function Item({ children, ...rest }) {
	//

	return <Element {...rest}>{children}</Element>
}

Item.Count = Count
Item.Title = Title
Item.Icon = Icon

export default Item
