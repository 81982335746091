import { routing } from '@app'
import {
	Element,
	Container,
	Side,
	Title,
	TextTyping,
	Desc,
	Advantage,
	ButtonLink,
	Picture,
	Dots,
	Dot,
	WaveImg,
} from './banner.styled'

import restPng from './img/rest.png'
import cafePng from './img/cafe.png'
import barPng from './img/bar.png'
import pabPng from './img/pab.png'
import hotelPng from './img/hotel.png'
import waveSvg from './img/wave.svg'

const carouselPictures = [restPng, cafePng, barPng, pabPng, hotelPng]

function Banner({ ...rest }) {
	//
	const [pictureIndex, setPictureIndex] = React.useState(0)

	const handlePictureChange = (i) => {
		setPictureIndex(carouselPictures.length === i ? 0 : i)
	}

	return (
		<Element {...rest}>
			<Container>
				<Side>
					<Title>
						Бесконтактное электронное меню для{' '}
						<TextTyping
							words={['ресторанов', 'кафе', 'баров', 'пабов', 'отелей']}
							onPictureChange={handlePictureChange}
						/>
					</Title>

					<Desc>
						Получите безопасное, интерактивное меню или сайт доставки для заведения, которое до 30%
						сокращает расходы и на 20 % повышает продажи
					</Desc>

					<Advantage>
						<li>Доступно по QR-коду и ссылке</li>
						<li>Не требует установки приложения</li>
						<li>Полное внедрение за 1 день</li>
					</Advantage>

					<ButtonLink href={routing.signupRoute} target="_blank">
						Попробовать бесплатно
					</ButtonLink>
				</Side>

				<Side>
					{carouselPictures.map((img, i) => (
						<Picture src={img} visible={pictureIndex === i} key={i} />
					))}
					<Dots counter={carouselPictures.length}>
						{carouselPictures.map((img, i) => (
							<Dot active={pictureIndex === i} key={i} />
						))}
					</Dots>
				</Side>
			</Container>
			<WaveImg src={waveSvg} />
		</Element>
	)
}

export default Banner
