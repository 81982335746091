import { Element, Title, Currency, Desc } from './item.styled'

function Item({ children, ...rest }) {
	//

	return <Element {...rest}>{children}</Element>
}

Item.Title = Title
Item.Currency = Currency
Item.Desc = Desc

export default Item
