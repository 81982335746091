export const Element = styled.section`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 30px 30px 60px;
	box-sizing: border-box;

	${MOBILE} {
		flex-flow: column;
		padding: 30px;
	}
`

export const Side = styled.div`
	:first-child {
		display: flex;
		align-items: flex-end;
		width: 45%;

		${MOBILE} {
			width: 100%;
		}
	}

	:last-child {
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		flex: 1;
		margin-left: 30px;

		${MOBILE} {
			margin-left: 0;
		}
	}
`

export const Title = styled.h2`
	font-size: 40px;

	span {
		font-weight: 600;
		color: ${({ theme }) => theme.color.blue};
	}

	${MOBILE} {
		font-size: 24px;
		text-align: center;
		margin: 0 auto;
	}
`

export const Desc = styled.p`
	margin-top: 30px;
	font-size: 18px;

	${MOBILE} {
		text-align: center;
	}
`

export const WorksWith = styled.span`
	margin-top: 30px;
	color: ${({ theme }) => theme.color.gray.two};

	${MOBILE} {
		display: none;
	}
`

export const Grid = styled.ul`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	margin-top: 5px;

	${MOBILE} {
		display: none;
	}
`

export const Item = styled.li`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	margin-right: 30px;

	:last-child {
		margin-right: 0;
	}
`

export const ItemIconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: ${({ theme }) => theme.color.gray.four};
`

export const ItemIcon = styled(UIIcon)`
	width: 24px;
	height: 24px;
	fill: ${({ theme }) => theme.color.blue};
`

export const ItemMain = styled.div`
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	line-height: 1.3;
	margin-left: 8px;

	span {
		:first-child {
			font-size: 12px;
		}
	}
`

export const Picture = styled.img`
	display: table;
	width: 120%;
	pointer-events: none;
	margin-left: -20%;

	${MOBILE} {
		margin-left: 0;
		width: 100%;
		margin-bottom: 30px;
	}
`
