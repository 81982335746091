import { Element, Head, Title, Icon, Main } from './item.styled'

import angleDownSvg from './img/angle-down.svg'

function Item({ children, ...rest }) {
	//
	const [show, setShow] = React.useState(false)
	const wrapperRef = React.useRef(null)

	React.useEffect(() => {
		function handleClickOutside(event) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setShow(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [wrapperRef, setShow])

	return (
		<Element {...rest} onClick={() => setShow(!show)}>
			<Head ref={wrapperRef}>
				{React.cloneElement(children[0], { ...children[0] })}
				<Icon src={angleDownSvg} {...{ show }} />
			</Head>

			{React.Children.map(children.slice(1, children.length), (child) =>
				React.cloneElement(child, { ...child, show }),
			)}
		</Element>
	)
}

Item.Title = Title
Item.Main = Main

export default Item
