export const Element = styled.li`
	border-radius: 30px;
	background: ${({ theme }) => theme.color.gray.five};
`

export const Head = styled.header`
	cursor: pointer;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	padding: 30px;
`

export const Title = styled.h2`
	flex: 1;
	margin-right: 30px;
	font-size: 24px;

	${MOBILE} {
		font-size: 20px;
		margin-right: 15px;
	}
`

export const Icon = styled(UIIcon)`
	width: 32px;
	height: 32px;
	fill: ${({ theme }) => rgba(theme.color.gray.two, 0.5)};
	transform: ${({ show }) => (show ? 'rotate(-180deg)' : 'none')};
	transition: fill 0.3s ease, transform 0.3s ease;

	${Head}:hover & {
		fill: ${({ theme }) => theme.color.gray.two};
	}
`

export const Main = styled.section`
	max-height: ${({ show }) => (show ? '500px' : '0')};
	margin-top: ${({ show }) => (show ? '-15px' : '0')};
	padding: ${({ show }) => (show ? '0 30px 30px' : '0 30px')};
	opacity: ${({ show }) => (show ? '1' : '0')};
	overflow: hidden;
	transition: max-height 0.3s ease, margin-top 0.3s ease, padding 0.3s ease, opacity 0.3s ease;

	::before {
		content: '';
		display: block;
		width: 30px;
		height: 1px;
		background: ${({ theme }) => theme.color.gray.three};
		margin-bottom: 15px;
	}

	ul {
		li {
			::after {
				content: '';
				display: block;
				width: 30px;
				height: 1px;
				background: ${({ theme }) => theme.color.gray.three};
				margin-bottom: 15px;
				margin-top: 15px;
			}

			:last-child {
				::after {
					display: none;
				}
			}
		}
	}
`
